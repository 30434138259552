.custom-select-container {
  width: 100%;
  position: relative;
  transition: all 1s ease-in-out;
  height: 100%;
  padding: 0;

  .dropdown__label {
    position: absolute;
    top: -6px;
    line-height: 1;
    padding: 0 2px;
    background-color: white;
    font-size: small;
    color: black;
    font-weight: 600;
  }

  .dropdown__label.required:after {
    content: ' *';
    color: red;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
      cursor: pointer;
      padding: 5px 10px;
    }
    li:hover {
      color: white;
      background-color: #274e24;
    }
  }

  .selected-text {
    border: solid rgba(39, 78, 36, 0.8) 1px;
    display: flex;
    align-items: center;
    margin: 0;
    border-radius: 7px;
    padding: 5px 20px 5px 10px;
    height: 100%;
    word-break: break-all;
    font-size: 0.9em;
    color: #274e24;
    font-weight: 600;
    background-color: white;
  }

  .selected-text::after {
    content: '';
    position: absolute;
    right: 7px;
    top: 40%;
    border: 7px solid transparent;
    border-color: #274e24 transparent transparent;
    cursor: pointer;
  }

  .select-options {
    margin-top: 20px;
    border: solid rgba(148, 148, 148, 0.8) 1px;
    padding: 0;
    z-index: 30;
    position: absolute;
    background-color: white;
    width: 100%;
    max-height: 160px;
    -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.12);
    overflow-y: auto;

    li {
      padding: 10px;
    }
  }
}
